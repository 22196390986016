<template>
    <div>
        <div class="">
        <div class="relative w-100">
            <div class="input-label">Название операции</div>
            <input
                v-model="name"
                type="text" class="admin-input  w-100"
                readonly onfocus="this.removeAttribute('readonly')" />

            <div class="input-label pt-2">Описание</div>
            <input
                v-model="description"
                type="text" class="admin-input  w-100"
                readonly onfocus="this.removeAttribute('readonly')" />

            <div class="input-label pt-2">Количество</div>
            <InputNumber
                v-model="amount"
                mode="decimal"
                class="inputnumber-input"
                :useGrouping="false" />
        </div>
        </div>

        <div class="d-flex pt-3" style="justify-content: space-between;">
            <button
                @click="closeModal"
                class="admin-auth-btn btn btn--border btn--fill">Отмена</button>
            <button
                @click="addOperation"
                class="admin-auth-btn btn btn--green btn--fill">подтвердить</button>
        </div>
    </div>
</template>

<script>
import InputNumber from 'primevue/inputnumber'

export default {
    name: 'AddFinanceOrder',
    components: {
        InputNumber
    },
    props: ['datestart'],
    data(){
        return {
            amount: 0,
            name: '',
            description: ''
        }
    },
    methods: {
        closeModal(){
            this.$emit('update:displayBasic', false)
        },
        addOperation(){
            this.$store.dispatch('admins/add_financeorder', {
                name: this.name,
                description: this.description,
                amount: this.amount
            })
            .then(res => {
                if(res.status == 'success'){
                    this.$store.dispatch('admins/get_financeorders', {
                        timestart: this.datestart.milisec / 1000,
                        timeend: Math.floor(Date.now() / 1000)
                    })
                }
                this.closeModal()
            })
        }
    }
}
</script>
