<template>
    <router-view />
</template>

<script>

export default {
    name: 'Reports',
}
</script>
