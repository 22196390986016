<template>
    <div>
        <div class="">
        <div class="relative w-100">
            <InputNumber
                v-model="avans"
                mode="decimal"
                class="inputnumber-input"
                :useGrouping="false" />
        </div>
        </div>

        <div class="d-flex pt-3" style="justify-content: space-between;">
            <button
                @click="closeModal"
                class="admin-auth-btn btn btn--border btn--fill">Отмена</button>
            <button
                @click="pay"
                :class="{'btn--disabled': !checkAvans}"
                class="admin-auth-btn btn btn--green btn--fill">подтвердить</button>
        </div>
    </div>
</template>

<script>
import InputNumber from 'primevue/inputnumber'

export default {
    name: 'Avans',
    components: {
        InputNumber
    },
    props: ['item'],
    data(){
        return {
            avans: 0
        }
    },
    computed: {
        checkAvans(){
            return this.avans > 0 && this.avans <= this.item.salary
        }
    },
    methods: {
        closeModal(){
            this.$emit('update:displayModal', false)
        },
        pay(){
            this.$store.dispatch('admins/pay_employee', {
                employeeid: this.item.id,
                amount: this.avans
            })
            .then(res => {
                if(res.status == 'success'){
                    this.$store.dispatch('admins/get_employees')
                }
                this.closeModal()
            })
        },
    }
}
</script>
