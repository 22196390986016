<template>
    <div>
        <div class="">
        <div class="relative w-100">
            <InputNumber
                v-model="salary"
                mode="decimal"
                class="inputnumber-input"
                :useGrouping="false" />
        </div>
        </div>

        <div class="d-flex pt-3" style="justify-content: space-between;">
            <button
                @click="closeModal"
                class="admin-auth-btn btn btn--border btn--fill">Отмена</button>
            <button
                @click="setSalary"
                class="admin-auth-btn btn btn--green btn--fill">подтвердить</button>
        </div>
    </div>
</template>

<script>
import InputNumber from 'primevue/inputnumber'

export default {
    name: 'Salary',
    components: {
        InputNumber
    },
    props: ['item'],
    data(){
        return {
            salary: 0
        }
    },
    methods: {
        closeModal(){
            this.$emit('update:displayModal', false)
        },
        setSalary(){
            this.$store.dispatch('admins/pay_employee', {
                amount: this.salary,
                employeeid: this.item.id
            })
            .then(res => {
                this.closeModal()
            })
        }
    }
}
</script>
