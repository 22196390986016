<template>
    <div class="admin-checks">
        <div class="admin-container">

            <div class="admin-reports-control">
                <div class="admin-reports-control-times">
                    <div class="admin-reports-control-date">

                        <div class="admin-reports-control-label">Дата начала</div>
                        <vue-date-picker
                            format="DD MM YYYY"
                            :color="'#87c03d'"
                            v-model="datestart.date">
                            <template #activator="{ date }">
                                <div ref="activator" class="relative w-100">
                                    <div class="admin-input-icon">
                                        <span class="a-calendar" style="color: #fff;font-size: 26px;"></span>
                                    </div>
                                    <input type="text" :value="date" class="admin-input admin-input--whith-icon w-100 no-event">
                                </div>
                            </template>
                        </vue-date-picker>
                    </div>
                    <div class="relative admin-reports-control-time">
                        <input
                            type="number"
                            v-model="datestart.hours"
                            @input="inputDateStart('datestart','hours')"
                            @change="changeDateTime('datestart','hours')"
                            class="admin-input w-100">
                    </div>
                    <div class="relative admin-reports-control-time">
                        <input
                            type="number"
                            v-model="datestart.minutes"
                            @input="inputDateStart('datestart','minutes')"
                            @change="changeDateTime('datestart','minutes')"
                            class="admin-input w-100">
                    </div>
                </div>

                <div class="admin-reports-control-times">
                    <div class="admin-reports-control-date">
                        <div class="admin-reports-control-label">Дата окончания</div>

                        <vue-date-picker
                            format="DD MM YYYY"
                            :color="'#87c03d'"
                            v-model="dateend.date">
                            <template #activator="{ date }">
                                <div ref="activator" class="relative w-100">
                                    <div class="admin-input-icon">
                                        <span class="a-calendar" style="color: #fff; font-size: 26px;"></span>
                                    </div>
                                    <input type="text" :value="date" class="admin-input admin-input--whith-icon w-100 no-event">
                                </div>
                            </template>
                        </vue-date-picker>

                    </div>
                    <div class="relative admin-reports-control-time">
                        <input
                            type="number"
                            v-model="dateend.hours"
                            @input="inputDateStart('dateend','hours')"
                            @change="changeDateTime('dateend','hours')"
                            class="admin-input w-100">
                    </div>
                    <div class="relative admin-reports-control-time">
                        <input
                            type="number"
                            v-model="dateend.minutes"
                            @input="inputDateStart('dateend','minute')"
                            @change="changeDateTime('dateend','minutes')"
                            class="admin-input w-100">
                    </div>
                </div>
            </div>

            <div class="admin-reports-date-control">
                <button
                    v-for="btn in filter_btn"
                    @click="onClickFilter(btn)"
                    class="btn btn--green btn--contur">{{btn.title}}</button>
                <div class="admin-header-btn-right">
                    <button
                        class="btn btn--green btn--fill"
                        @click="addFinanceOrder"
                        >Новая операция</button>
                </div>
            </div>

            <div class="admin-checks-table" id="table-checks">
                <div class="admin-checks-tr admin-checks-thead">
                    <div class="admin-checks-cell admin-checks-cell-number">ID</div>
                    <div class="admin-checks-cell admin-checks-cell-date">Дата</div>
                    <div class="admin-checks-cell admin-checks-cell-employe">Тип операции</div>
                    <div class="admin-checks-cell admin-checks-cell-total">Сумма</div>
                    <div class="admin-checks-cell justify-center"></div>
                </div>

                <div
                    v-for="item in getOrders.orders"
                    class="admin-checks-tr">
                    <div class="admin-checks-cell admin-checks-cell-number">{{item.id}}</div>
                    <div class="admin-checks-cell admin-checks-cell-date">{{item.timeFormated}}</div>
                    <div class="admin-checks-cell admin-checks-cell-employe">{{item.name}}</div>
                    <div class="admin-checks-cell admin-checks-cell-total">{{formatspace({text: item.amount})}}</div>
                    <div class="admin-checks-cell justify-center">
                        <span
                            @click="deleteOperation(item)"
                            class="a-delete btn-icon"></span>
                    </div>
                </div>
            </div>

            <pagination
                :v-if="true"
                :length="getOrders.len"
                :perPage.sync="perPage"
                @paginator="onPage"/>
        </div>

        <Dialog
            :showHeader="false"
            :modal="true"
            :dismissableMask="true"
            :closable="true"
            :visible.sync="displayBasic">
            <div class="header">
                <span
                    @click="closeBasic"
                    class="btn-close">&#10005;</span>
            </div>
            <div class="popup">
                <div class="popup__title">Новая операция</div>
                <div class="popup-fields">
                    <add-finance-order
                        :datestart="datestart"
                        :displayBasic.sync="displayBasic"
                        />
                </div>
            </div>
        </Dialog>

        <Dialog
            :showHeader="false"
            :modal="true"
            :dismissableMask="true"
            :closable="true"
            :visible.sync="displayCancel">
            <div class="header">
                <span
                    @click="closeCancel"
                    class="btn-close">&#10005;</span>
            </div>
            <div class="popup">
                <div class="popup__title">Удаление операции</div>
                <div class="popup-fields">
                    <cancel-operation
                        :item="item"
                        :datestart="datestart"
                        @closeCancel="closeCancel"
                        />
                </div>
            </div>
        </Dialog>
    </div>
</template>

<script>
import {VueDatePicker} from '@/utils/vue-datepicker.esm.js'
import Pagination from "@/components/common/Pagination"
import {formatspace} from "@/utils/services"
import AddFinanceOrder from './financeorder/AddFinanceOrder'
import CancelOperation from './financeorder/CancelOperation'

export default {
    name: 'FinanceOrder',
    components: {
        VueDatePicker,
        Pagination,
        AddFinanceOrder,
        CancelOperation
    },
    data(){
        return {
            displayBasic: false,
            displayCancel: false,
            checkActive: {},
            datestart: this.transDate(+new Date((new Date()).getFullYear(), (new Date()).getMonth(), 1)),
            dateend: this.transDate(Date.now()),
            filter_btn: [{
                name: 'btn_today',
                title: 'сегодня',
                sts: false,
            },{
                name: 'btn_yest',
                title: 'вчера',
                sts: false,
            },{
                name: 'btn_week',
                title: 'неделя',
                sts: false,
            },{
                name: 'btn_month',
                title: 'месяц',
                sts: false,
            }],
            filter_active: false,
            activeStatus: null,
            page: 1,
            perPage: 10,
            selectedshopid: -1,
            status: ['Открыт', 'Оплачено', 'Отмена'],
            item: {}
        }
    },
    computed: {
        getOrders(){
            let count = 0
            let sales = 0
            let returns = 0
            //0, 1,2 - открыт закрыт (оплачен ) и неоплачен (отменен )
            const role = this.$store.state.auth.role
            const orders = this.$store.state.admins.financeorder
            // const cashiers = this.$store.state.shops.cashiers
            // const status = this.activeStatus


            const dstart = this.transDate(+new Date(this.datestart.date))
            const hstart = this.checkHours(this.datestart.hours)
            const mstart = this.checkMinutes(this.datestart.minutes)

            const dend = this.transDate(+new Date(this.dateend.date))
            const hend = this.checkHours(this.dateend.hours)
            const mend = this.checkMinutes(this.dateend.minutes)

            let list = orders.filter(order => {
                if(order.status == 0){
                    return false
                }

                const time = +new Date(order.time * 1000)

                let startDay = +new Date(dstart.year, dstart.month, dstart.day, hstart, mstart)
                let endDay = +new Date(dend.year, dend.month, dend.day, hend, mend, 59)

                if(time >= startDay && time <= endDay){
                    order.timeFormated = this.formatDate(order.time)
                    if(order.status == 1){
                        return order
                    }
                }
            })
            // пагинация
            const list_filtered = list.slice((this.page - 1) * this.perPage, this.perPage
                ? (this.page - 1) * this.perPage + this.perPage
                : list.length)

            return {
                len: list.length,
                list: list,
                orders: list_filtered
            }
        },
        getRole(){
            return this.$store.state.auth.role
        }
    },
    methods: {
        deleteOperation(item){
            this.item = item
            this.displayCancel = true
        },
        closeCancel(){
            this.displayCancel = false
            this.item = {}
        },
        formatspace(data){
            return formatspace(data)
        },
        openBasic(){
            this.displayBasic = true
        },
        closeBasic(){
            this.displayBasic = false
        },
        onPage(pageNum){
            this.page = pageNum
        },
        onClickFilter(btn){
            this.filter_btn = this.filter_btn.map(item => {
                if(item.name == btn.name){
                    item.sts = !btn.sts
                    this.filter_active = item.name
                }
                else{
                    item.sts = false
                }
                return item
            })
            if(this.filter_active){
                let startDay = ''
                let endDay = ''
                let today = new Date()

                if(this.filter_active == 'btn_today'){
                    startDay = +new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0)
                    endDay = +new Date()
                }
                else if(this.filter_active == 'btn_yest'){
                    endDay = +new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0)
                    startDay = +new Date(endDay - (24 * 60 * 60 * 1000))
                }
                else if(this.filter_active == 'btn_week'){
                    startDay += new Date(today.getFullYear(), today.getMonth(), today.getDate() - (today.getDay() ? today.getDay() - 1 : 6))
                    endDay = +new Date()
                }
                else if(this.filter_active == 'btn_month'){
                    startDay = +new Date(today.getFullYear(), today.getMonth(), 1)
                    endDay = +new Date()
                }
                this.datestart = this.transDate(startDay)
                this.dateend = this.transDate(endDay)
            }
        },
        checkHours(hour){
            const dig = parseInt(hour)
            if(dig >= 0 && dig <= 24){
                return hour
            }
            else{
                return '00'
            }
        },
        checkMinutes(min){
            const dig = parseInt(min)
            if(dig >= 0 && dig < 59){
                return min
            }
            else{
                return '00'
            }
        },
        inputDateStart(param, prop){},
        changeDateTime(param, prop){
            this[param][prop] = String(this[param][prop]).padStart(2, "0")
        },
        setActiveReport(item){
            this.reports.map(rep => {
                if(rep.active){
                    rep.active = false
                }
                if(rep.name == item.name){
                    rep.active = true
                    this.activeStatus = rep.status
                }
            })
        },
        formatDate(date, type = 'full'){
            let options = {}
            if(type == 'dmy'){
                options = {year: '2-digit', month: '2-digit', day: '2-digit'}
            }
            else{
                options = {year: '2-digit', month: '2-digit', day: '2-digit', hour: '2-digit', minute:  '2-digit'}
            }
            const formatdate = new Date(date * 1000)
            return formatdate.toLocaleDateString("ru-RU", options)
        },
        transDate(date){
            const formatdate = new Date(date)
            // return formatdate.toLocaleDateString("ru-RU", options)
            return {
                milisec: formatdate,
                date: formatdate.getFullYear() + '-' + (formatdate.getMonth()+1) + '-' + formatdate.getDate(),
                year: formatdate.getFullYear(),
                month: formatdate.getMonth(),
                day: formatdate.getDate(),
                hours: String(formatdate.getHours()).padStart(2, "0"),
                minutes: String(formatdate.getMinutes()).padStart(2, "0")
            }
        },
        addFinanceOrder(){
            this.displayBasic = true
        }
    },
    created(){
        this.$store.dispatch('admins/get_financeorders', {
            timestart: this.datestart.milisec / 1000,
            timeend: Math.floor(this.dateend.milisec / 1000)
        })
    },
    beforeDestroy(){
        this.$store.dispatch('admins/clearFinanceorder')
    }
}
</script>

<style>

</style>
