import Vue from 'vue'
import Vuex from 'vuex'
import auth from './modules/auth'
import shops from './modules/shops'
import common from './modules/common'
import admins from './modules/admins'

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        auth,
        shops,
        common,
        admins
    }
})
