<template>
    <div>
        <div class="">
            <div class="relative w-100">
                <div class="text--white">ID {{item.id}}</div>
                <div class="text--white pt-1">Тип: {{item.name}}</div>
                <div class="text--white pt-1">{{item.timeFormated}}</div>
                <div class="text--white pt-1">Сумма: {{item.amount}}</div>
                <div class="input-label pt-2">Описание</div>
                <input
                    v-model="description"
                    type="text" class="admin-input  w-100"
                    readonly onfocus="this.removeAttribute('readonly')" />
            </div>
        </div>

        <div class="d-flex pt-3" style="justify-content: space-between;">
            <button
                @click="closeModal"
                class="admin-auth-btn btn btn--border btn--fill">Отмена</button>
            <button
                @click="cancelOperation"
                class="admin-auth-btn btn btn--green btn--fill">подтвердить</button>
        </div>
    </div>
</template>

<script>
import InputNumber from 'primevue/inputnumber'

export default {
    name: 'CancelOperation',
    components: {
        InputNumber
    },
    props: ['item', 'datestart'],
    data(){
        return {
            description: ''
        }
    },
    methods: {
        closeModal(){
            this.$emit('closeCancel')
        },
        cancelOperation(){
            this.$store.dispatch('admins/cancel_financeorder', {
                orderid: this.item.id,
                declinereason: this.description || ''
            })
            .then(res => {
                if(res.status == 'success'){
                    this.$store.dispatch('admins/get_financeorders', {
                        timestart: this.datestart.milisec / 1000,
                        timeend: Math.floor(Date.now() / 1000)
                    })
                }
                this.$emit('closeCancel')
            })
        }
    }
}
</script>
