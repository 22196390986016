<template>
    <div class="admin-checks">
        <div class="admin-container">

            <button
                class="btn btn--green btn--fill"
                @click="openModal(null, 'addemployee')"
                >Новый сотрудник</button>

            <div class="admin-checks-table">
                <div class="admin-checks-tr admin-checks-thead">
                    <div class="admin-checks-cell admin-checks-cell-number">ID</div>
                    <div class="admin-checks-cell admin-checks-cell-date">Имя</div>
                    <div class="admin-checks-cell admin-checks-cell-employe">Должность</div>
                    <div class="admin-checks-cell admin-checks-cell-client">Зарплата</div>
                    <div class="admin-checks-cell justify-center">Выплата</div>
                    <div class="admin-checks-cell justify-center">История</div>
                    <div class="admin-checks-cell justify-center">Активен</div>
                </div>

                <div
                    v-for="item in getEmployees.list"
                    @click=""
                    class="admin-checks-tr">
                    <div class="admin-checks-cell admin-checks-cell-number">{{item.id}}</div>
                    <div
                        @click="changeProp(item)"
                        class="admin-checks-cell admin-checks-cell--edit relative">
                        <confirm-popup
                            v-if="editUserId == item.id && focusprop == 'name'"
                            @cancelProp="cancelProp"
                            :value="item.name"
                            :item="item"
                            />
                        {{item.name}}</div>
                    <div class="admin-checks-cell admin-checks-cell-employe">{{item.position}}</div>
                    <div
                        @click="changeSalary(item)"
                        class="admin-checks-cell admin-checks-cell--edit relative">
                        <confirm-salary
                            v-if="editUserId == item.id && focusprop == 'salary'"
                            @cancelProp="cancelProp"
                            :value="item.salary"
                            :item="item"
                            />
                        {{formatspace({text: item.salary})}}</div>

                    <div class="admin-checks-cell justify-center">
                        <span
                            @click="openModal(item, 'avans')"
                            class="a-avans btn-icon"></span>
                    </div>
                    <div class="admin-checks-cell justify-center">
                        <span
                            @click="openModal(item, 'history')"
                            class="a-history btn-icon"></span>
                    </div>
                    <div class="admin-checks-cell justify-center">
                        <toggle-button
                            :color="switch_color"
                            :height="20"
                            :width="45"
                            :value="!!item.active"
                            @change="setItemActive(item)"
                            />
                    </div>
                </div>
            </div>

            <pagination
                :v-if="true"
                :length="getEmployees.len"
                :perPage.sync="perPage"
                @paginator="onPage"/>
        </div>

        <Dialog
            :showHeader="false"
            :modal="true"
            :dismissableMask="false"
            :closable="true"
            :visible.sync="displayModal"
            :class="{addmenu: modalComponent == 'History'}"
            >
            <div class="header">
                <span
                    @click="closeModal"
                    class="btn-close">&#10005;</span>
            </div>
            <div class="popup">
                <div class="popup__title">{{modalTitle}}</div>
                <div class="popup-fields">
                    <component
                        :is="modalComponent"
                        :displayModal.sync="displayModal"
                        :item="item"
                        @closeModal="closeModal"
                        ></component>
                </div>
            </div>
        </Dialog>
    </div>
</template>

<script>
import Pagination from "@/components/common/Pagination"
import {formatspace} from "@/utils/services"
import History from "./employees/History"
import Salary from "./employees/Salary"
import Avans from "./employees/Avans"
import AddEmployee from "./employees/AddEmployee"
import { ToggleButton } from 'vue-js-toggle-button'
import ConfirmPopup from "./employees/ConfirmPopup"
import ConfirmSalary from "./employees/ConfirmSalary"

export default {
    name: 'Employees',
    components: {
        Pagination,
        History,
        Salary,
        Avans,
        AddEmployee,
        ToggleButton,
        ConfirmPopup,
        ConfirmSalary
    },
    data(){
        return {
            page: 1,
            perPage: 10,
            displayModal: false,
            modalComponent: '',
            modalTitle: '',
            item: null,
            editUserId: '',
            focusprop: '',
            switch_color: {
                checked: '#87c03d',
                unchecked: '#525252'
            },
        }
    },
    computed: {
        getEmployees(){
            let list = this.$store.state.admins.employees
            const count = list.length
            // пагинация
            list = list.slice((this.page - 1) * this.perPage, this.perPage
                ? (this.page - 1) * this.perPage + this.perPage
                : list.length)
            return {
                list: list,
                len: count
            }
        },
        getRole(){
            return this.$store.state.auth.role
        }
    },
    methods: {
        changeProp(item){
            this.editUserId = item.id
            this.focusprop = 'name'
        },
        changeSalary(item){
            this.editUserId = item.id
            this.focusprop = 'salary'
        },
        cancelProp(){
            this.editUserId = ''
            this.focusprop = ''
        },
        formatspace(data){
            return formatspace(data)
        },
        setItemActive(item){
            if(this.getRole == 'superadmin'){
                return
            }
            const shop = parseInt(this.$store.state.auth.shopid)
            const active = item.active ? 0 : 1
            this.$store.dispatch('admins/change_employee', {
                id: item.id,
                active: active
            })
            .then(res => {
                if(res.status == 'success'){
                    this.$store.dispatch('admins/get_employees')
                }
            })
        },
        openModal(item, type){
            this.displayModal = true
            let title = ''
            let component = ''
            this.item = item
            switch(type){
                case 'avans':
                    title = 'Выплата'
                    component = 'Avans'
                    break
                case 'salary':
                    title = 'Зарплата',
                    component = 'Salary'
                    break
                case 'history':
                    title = 'История выплат',
                    component = 'History'
                    break
                case 'addemployee':
                    title = 'Новый сотрудник',
                    component = 'AddEmployee'
                    break
                default:
                    title = '',
                    component = ''
            }
            this.modalComponent = component
            this.modalTitle = title
        },
        closeModal(){
            this.displayModal = false
            this.modalComponent = ''
            this.modalTitle = ''
            this.item = null
        },
        onPage(pageNum){
            this.page = pageNum
        },
        formatDate(date, type = 'full'){
            let options = {}
            if(type == 'dmy'){
                options = {year: '2-digit', month: '2-digit', day: '2-digit'}
            }
            else{
                options = {year: '2-digit', month: '2-digit', day: '2-digit', hour: '2-digit', minute:  '2-digit'}
            }
            const formatdate = new Date(date * 1000)
            return formatdate.toLocaleDateString("ru-RU", options)
        },
        transDate(date){
            const formatdate = new Date(date)
            return {
                milisec: formatdate,
                date: formatdate.getFullYear() + '-' + (formatdate.getMonth()+1) + '-' + formatdate.getDate(),
                year: formatdate.getFullYear(),
                month: formatdate.getMonth(),
                day: formatdate.getDate(),
                hours: String(formatdate.getHours()).padStart(2, "0"),
                minutes: String(formatdate.getMinutes()).padStart(2, "0")
            }
        },
    },
    created(){
        this.$store.dispatch('admins/get_employees')
    },
    beforeDestroy(){
        this.$store.dispatch('admins/clearEmployees')
    }
}
</script>
