<template>
    <div>
        <div style="color: #fff;">Сотрудник: {{item.name}}</div>

        <div class="admin-checks-table">
            <div class="admin-checks-tr admin-checks-thead grid-3">
                <div class="admin-checks-cell">Тип</div>
                <div class="admin-checks-cell">Время</div>
                <div class="admin-checks-cell">Выплата</div>
            </div>

            <div
                v-for="item in getList"
                @click=""
                class="admin-checks-tr grid-3">
                <div class="admin-checks-cell admin-checks-cell-number">{{item.name}}</div>
                <div class="admin-checks-cell admin-checks-cell-date">
                    {{formatDate(item.time)}}</div>
                <div class="admin-checks-cell admin-checks-cell-employe">
                    {{formatspace({text: item.amount < 0 ? item.amount * (-1) : item.amount})}}</div>
            </div>
        </div>


        <div class="admin-search d-flex gap-2 pt-3">
            <button
                @click="closeModal"
                class="admin-auth-btn btn btn--border btn--fill">Отмена</button>
        </div>
    </div>
</template>

<script>
import {formatspace} from "@/utils/services"

export default {
    name: 'History',
    components: {
    },
    props: ['item'],
    data(){
        return {
            list: {
                date: '11.12.23, 17:12',
                avans: 100000,
                salary: 300000,
            },
        }
    },
    computed: {
        getList(){
            let list = this.$store.state.admins.salaryhistory || []
            return list
        },
    },
    methods: {
        closeModal(){
            this.$emit('update:displayModal', false)
        },
        formatspace(data){
            return formatspace(data)
        },
        formatDate(date, type = 'full'){
            console.log(date)
            let options = {}
            if(type == 'dmy'){
                options = {year: '2-digit', month: '2-digit', day: '2-digit'}
            }
            else{
                options = {year: '2-digit', month: '2-digit', day: '2-digit', hour: '2-digit', minute:  '2-digit'}
            }
            const formatdate = new Date(date * 1000)
            return formatdate.toLocaleDateString("ru-RU", options)
        },
    },
    created(){
        this.$store.dispatch('admins/get_salaryhistory', {employeeid: this.item.id})
    }
}
</script>
