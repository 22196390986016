<template>
    <div class="admin-checks">
        <div class="admin-container">

            <div class="admin-checks-table" style="margin-top: 0px;">
                <div class="admin-checks-tr admin-checks-thead">
                    <div class="admin-checks-cell admin-checks-cell-number">ID</div>
                    <div class="admin-checks-cell admin-checks-cell-date">Имя</div>
                    <div class="admin-checks-cell admin-checks-cell-client">Выплачено</div>
                    <div class="admin-checks-cell admin-checks-cell-client">Общий доход</div>
                    <div class="admin-checks-cell justify-center">Выплата</div>
                </div>

                <div
                    v-for="item in getAffiliates.list"
                    @click=""
                    class="admin-checks-tr">
                    <div class="admin-checks-cell admin-checks-cell-number">{{item.id}}</div>
                    <div class="admin-checks-cell relative">
                        {{item.name}}</div>
                    <div class="admin-checks-cell relative">
                        {{formatspace({text: item.total_amount_paid})}}</div>
                    <div class="admin-checks-cell relative">
                        {{formatspace({text: item.total_referral_earnings})}}</div>

                    <div class="admin-checks-cell justify-center">
                        <span
                            @click="openModal(item, 'avans')"
                            class="a-avans btn-icon"></span>
                    </div>
                </div>
            </div>

            <pagination
                :v-if="true"
                :length="getAffiliates.len"
                :perPage.sync="perPage"
                @paginator="onPage"/>
        </div>

        <Dialog
            :showHeader="false"
            :modal="true"
            :dismissableMask="false"
            :closable="true"
            :visible.sync="displayModal"
            :class="{addmenu: modalComponent == 'History'}"
            >
            <div class="header">
                <span
                    @click="closeModal"
                    class="btn-close">&#10005;</span>
            </div>
            <div class="popup">
                <div class="popup__title">{{modalTitle}}</div>
                <div class="popup-fields">
                    <component
                        :is="modalComponent"
                        :displayModal.sync="displayModal"
                        :item="item"
                        @closeModal="closeModal"
                        ></component>
                </div>
            </div>
        </Dialog>
    </div>
</template>

<script>
import Pagination from "@/components/common/Pagination"
import {formatspace} from "@/utils/services"
import History from "./affiliates/History"
import Avans from "./affiliates/Avans"
import { ToggleButton } from 'vue-js-toggle-button'
import ConfirmSalary from "./affiliates/ConfirmSalary"

export default {
    name: 'Affiliates',
    components: {
        Pagination,
        History,
        ToggleButton,
        ConfirmSalary,
        Avans
    },
    data(){
        return {
            page: 1,
            perPage: 10,
            displayModal: false,
            modalComponent: '',
            modalTitle: '',
            item: null,
            editUserId: '',
            focusprop: '',
            switch_color: {
                checked: '#87c03d',
                unchecked: '#525252'
            },
        }
    },
    computed: {
        getAffiliates(){
            let list = this.$store.state.admins.affiliates
            const count = list.length
            // пагинация
            list = list.slice((this.page - 1) * this.perPage, this.perPage
                ? (this.page - 1) * this.perPage + this.perPage
                : list.length)
            return {
                list: list,
                len: count
            }
        },
        getRole(){
            return this.$store.state.auth.role
        }
    },
    methods: {
        changeProp(item){
            this.editUserId = item.id
            this.focusprop = 'name'
        },
        changeSalary(item){
            this.editUserId = item.id
            this.focusprop = 'salary'
        },
        cancelProp(){
            this.editUserId = ''
            this.focusprop = ''
        },
        formatspace(data){
            return formatspace(data)
        },
        setItemActive(item){
            if(this.getRole == 'superadmin'){
                return
            }
            const shop = parseInt(this.$store.state.auth.shopid)
            const active = item.active ? 0 : 1
            this.$store.dispatch('admins/change_employee', {
                id: item.id,
                active: active
            })
            .then(res => {
                if(res.status == 'success'){
                    this.$store.dispatch('admins/get_employees')
                }
            })
        },
        openModal(item, type){
            this.displayModal = true
            let title = ''
            let component = ''
            this.item = item
            switch(type){
                case 'avans':
                    title = 'Выплата'
                    component = 'Avans'
                    break
                case 'history':
                    title = 'История выплат',
                    component = 'History'
                    break
                default:
                    title = '',
                    component = ''
            }
            this.modalComponent = component
            this.modalTitle = title
        },
        closeModal(){
            this.displayModal = false
            this.modalComponent = ''
            this.modalTitle = ''
            this.item = null
        },
        onPage(pageNum){
            this.page = pageNum
        },
        formatDate(date, type = 'full'){
            let options = {}
            if(type == 'dmy'){
                options = {year: '2-digit', month: '2-digit', day: '2-digit'}
            }
            else{
                options = {year: '2-digit', month: '2-digit', day: '2-digit', hour: '2-digit', minute:  '2-digit'}
            }
            const formatdate = new Date(date * 1000)
            return formatdate.toLocaleDateString("ru-RU", options)
        },
        transDate(date){
            const formatdate = new Date(date)
            return {
                milisec: formatdate,
                date: formatdate.getFullYear() + '-' + (formatdate.getMonth()+1) + '-' + formatdate.getDate(),
                year: formatdate.getFullYear(),
                month: formatdate.getMonth(),
                day: formatdate.getDate(),
                hours: String(formatdate.getHours()).padStart(2, "0"),
                minutes: String(formatdate.getMinutes()).padStart(2, "0")
            }
        },
    },
    created(){
        this.$store.dispatch('admins/get_affiliates')
    },
    beforeDestroy(){
        this.$store.dispatch('admins/clearAffiliates')
    }
}
</script>
