<template>
    <div
        class="confirm-popup--menu"
        >
        <div
            @click.stop="cancel"
            class="admin-input-icon--popup">
            <span class="a-cancel" style="color: #ff0000;font-size: 36px;"></span>
        </div>
        <div
            @click.stop="changeName"
            class="admin-input-icon--popup">
            <span class="a-check" style="color: #87c03d;font-size: 36px;"></span>
        </div>
        <input
            v-model="val"
            class="admin-input"
            readonly onfocus="this.removeAttribute('readonly')">

    </div>
</template>

<script>
export default {
    name: 'ConfirmPopup',
    props: ['item', 'propname', 'value'],
    data(){
        return {
            val: this.value
        }
    },
    methods: {
        onclick(evt){
            if(!evt.target.closest('.confirm-popup--menu') && !evt.target.closest('.admin-input-icon--popup')){
                this.$emit('cancelProp')
            }
        },
        changeName(){
            this.$store.dispatch('admins/change_employee', {
                id: this.item.id,
                name: this.val
            })
            .then(res => {
                if(res.status == 'success'){
                    this.$store.dispatch('admins/get_employees')
                }
                this.cancel()
            })
        },
        cancel(){
            this.$emit('cancelProp')
        }
    },
    created(){
        document.addEventListener('mouseup', this.onclick, false)
    },
    beforeDestroy(){
        document.removeEventListener('mouseup', this.onclick)
    }
}
</script>
