<template>
    <div class="admin-header-nav">
        <div class="admin-container">
            <div
                v-for="item in getMenu"
                class="admin-header-nav-item-wrap">
                <router-link
                    :to="item.link"
                    class="admin-header-nav-item"
                    active-class="admin-header-nav-item-active">
                    {{item.title}}
                </router-link>
                <div
                    class="admin-header-subnav-item-wrap"
                    v-if="item.children">
                    <!-- <div
                        v-for="subitem in item.children"
                        class="admin-header-subnav-item"
                        @click="setTab(subitem.link)"
                        >
                        {{subitem.title}}
                    </div> -->

                    <router-link
                        :to="item.link + '/' + subitem.link"
                        v-for="subitem in item.children"
                        class="admin-header-subnav-item"
                        active-class="admin-header-subnav-item-active">
                        {{subitem.title}}
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'AppHeaderMenu',
    data(){
        return {
            list: [{
                title: 'Мой магазин',
                role: ['superadmin', 'admin'],
                link: '/registration'
            },{
                title: 'Заказы',
                role: ['superadmin', 'admin'],
                link: '/orders'
            },{
                title: 'Отчеты',
                role: ['superadmin', 'admin'],
                link: '/reports',
                children: [{
                    title: 'Отчеты',
                    role: ['superadmin', 'admin'],
                    link: 'detail'
                },{
                    title: 'Выписка',
                    role: ['superadmin', 'admin'],
                    link: 'statement'
                }]
            },{
                title: 'Чеки',
                role: ['superadmin', 'admin'],
                link: '/checks'
            },{
                title: 'Меню',
                role: ['superadmin', 'admin'],
                link: '/menu'
            },{
                title: 'Сотрудники',
                role: ['admin'],
                link: '/employees'
            },{
                title: 'Рефералы',
                role: ['admin'],
                link: '/affiliates'
            },{
                title: 'Операции',
                role: ['admin'],
                link: '/financeorder'
            }]
        }
    },
    computed: {
        getMenu(){
            const userRole = this.$store.state.auth.role
            const list = this.list.filter(item => item.role.some(role => role == userRole))
            return list
        }
    },
    methods: {
        setTab(page){
            this.$store.dispatch('common/setReports', page)
        }
    }
}
</script>
