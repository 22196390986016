import { http, api } from '@/utils/define'

const state = {
    employees: [],
    salaryhistory: [],
    affiliates: [],
    financeorder: []
}

const mutations = {
    setEmployees(state, pld){
        state.employees = pld
    },
    setHistory(state, pld){
        state.salaryhistory = pld
    },
    setAffiliates(state, pld){
        state.affiliates = pld
    },
    setFinanceorder(state, pld){
        state.financeorder = pld
    },
}
const actions = {
    get_employees({rootState, commit}){
        return new Promise((resolve) => {
            api.get(http['host3'], api['getemployees'], {shopid: rootState.auth.shopid, userid: rootState.auth.userid})
            .then(res => {
                if(res.status == 'success'){
                    const list = res.employees
                        .sort((a, b) => (a.regtime > b.regtime) ? 1 : ((b.regtime > a.regtime) ? -1 : 0))
                    commit('setEmployees', list)
                }
            })
        })
    },
    change_employee({rootState, commit}, pld){
        return new Promise((resolve) => {
            api.post(http['host3'], api['changeemployee'], {
                ...pld,
                shopid: rootState.auth.shopid
            }, {shopid: rootState.auth.shopid})
            .then(res => {
                resolve(res)
            })
        })
    },
    clearEmployees({commit}){
        commit('setEmployees', [])
    },
    get_salaryhistory({rootState, commit}, pld){
        return new Promise((resolve) => {
            api.get(http['host3'], api['getsalaryhistory'], {shopid: rootState.auth.shopid, employeeid: pld.employeeid})
            .then(res => {
                if(res.status == 'success'){
                    commit('setHistory', res.history)
                }
            })
        })
    },
    add_employee({rootState, commit}, pld){
        return new Promise((resolve) => {
            api.post(http['host3'], api['addemployee'], {
                ...pld,
                shopid: rootState.auth.shopid
            }, {shopid: rootState.auth.shopid})
            .then(res => {
                resolve(res)
            })
        })
    },
    pay_employee({rootState, commit}, pld){
        return new Promise((resolve) => {
            api.post(http['host3'], api['payemployee'], {
                ...pld,
                shopid: rootState.auth.shopid
            }, {shopid: rootState.auth.shopid})
            .then(res => {
                resolve(res)
            })
        })
    },
    get_affiliates({rootState, commit}){
        return new Promise((resolve) => {
            api.post(http['host3'], api['getaffiliates'],{shopid: rootState.auth.shopid}, {shopid: rootState.auth.shopid, userid: rootState.auth.userid})
            .then(res => {
                if(res.status == 'success'){
                    commit('setAffiliates', res.affiliates.affiliates || [])
                }
            })
        })
    },
    clearAffiliates({commit}){
        commit('setAffiliates', [])
    },
    pay_affiliate({rootState, commit}, pld){
        return new Promise((resolve) => {
            api.post(http['host3'], api['payaffiliate'], {
                ...pld,
                shopid: rootState.auth.shopid
            }, {shopid: rootState.auth.shopid})
            .then(res => {
                resolve(res)
            })
        })
    },
    get_financeorders({rootState, commit}, pld){
        return new Promise((resolve) => {
            api.post(http['host3'], api['getfinanceorders'],{
                shopid: rootState.auth.shopid,
                // timestart: (+new Date('2023-12-1')) / 1000,
                // timeend: (+new Date('2024-1-18')) / 1000
                timestart: pld.timestart,
                timeend: pld.timeend
            }, {shopid: rootState.auth.shopid, userid: rootState.auth.userid})
            .then(res => {
                if(res.status == 'success'){
                    commit('setFinanceorder', res.orders || [])
                }
            })
        })
    },
    clearFinanceorder({commit}){
        commit('setFinanceorder', [])
    },
    add_financeorder({rootState, commit}, pld){
        return new Promise((resolve) => {
            api.post(http['host3'], api['addfinanceorder'], {
                ...pld,
                shopid: rootState.auth.shopid
            }, {shopid: rootState.auth.shopid, userid: rootState.auth.userid})
            .then(res => {
                resolve(res)
            })
        })
    },
    cancel_financeorder({rootState, commit}, pld){
        return new Promise((resolve) => {
            api.post(http['host3'], api['cancelfinanceorder'], {
                ...pld,
                shopid: rootState.auth.shopid
            }, {shopid: rootState.auth.shopid, userid: rootState.auth.userid})
            .then(res => {
                resolve(res)
            })
        })
    },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
