import { http, api } from '@/utils/define'

const state = {
    currentReports: 'Reports'
}

const mutations = {
    setReports(state, pld){
        state.currentReports = pld
    }
}
const actions = {
    setReports({commit}, pld){
        commit('setReports', pld)
    }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
