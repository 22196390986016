<template>
    <div>
        <div class="">
        <div class="relative w-100">
            <div class="input-label">Имя</div>
            <input
                v-model="name"
                type="text" class="admin-input  w-100"
                readonly onfocus="this.removeAttribute('readonly')" />

            <div class="input-label pt-2">Должность</div>
            <input
                v-model="position"
                type="text" class="admin-input  w-100"
                readonly onfocus="this.removeAttribute('readonly')" />

            <div class="input-label pt-2">Зарплата</div>
            <InputNumber
                v-model="salary"
                mode="decimal"
                class="inputnumber-input"
                :useGrouping="false" />
        </div>
        </div>

        <div class="d-flex pt-3" style="justify-content: space-between;">
            <button
                @click="closeModal"
                class="admin-auth-btn btn btn--border btn--fill">Отмена</button>
            <button
                @click="addEmployee"
                class="admin-auth-btn btn btn--green btn--fill">подтвердить</button>
        </div>
    </div>
</template>

<script>
import InputNumber from 'primevue/inputnumber'

export default {
    name: 'AddEmployee',
    components: {
        InputNumber
    },
    data(){
        return {
            salary: 0,
            name: '',
            position: ''
        }
    },
    methods: {
        closeModal(){
            this.$emit('update:displayModal', false)
        },
        addEmployee(){
            this.$store.dispatch('admins/add_employee', {
                name: this.name,
                position: this.position,
                salary: this.salary
            })
            .then(res => {
                if(res.status == 'success'){
                    this.$store.dispatch('admins/get_employees')
                }
                this.closeModal()
            })
        }
    }
}
</script>
